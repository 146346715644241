<template>
  <div class="form-container">
    <div>
      <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">
        <validation-observer ref="simpleRules" class="form" v-for="(item, index) in items" :key="item.id">
          <!-- Row Loop -->
          <div class="line-wrap">
            <div class="index">#{{index + 1}}</div>
            <b-row :id="item.id" ref="row">
              <b-col xl="1" lg="6" md="6" sm="12">
                <b-form-group label="QTY" label-for="qty">
                  <validation-provider #default="{ errors }" name="qty" rules="required">
                    <b-form-input id="qty" v-model="item.form.qty" type="number"
                      :state="errors.length > 0 || (item.form.qty && !/^\d+$/.test(item.form.qty)) ? false : null"
                      placeholder="qty" @change="handleFormChange()" />
                    <b-form-invalid-feedback tooltip>
                      {{ $t('calculate.notCharacters') }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xl="1" lg="6" md="6" sm="12">
                <b-form-group :label="$t('calculate.weight') + ' KG'" label-for="weight">
                  <validation-provider #default="{ errors }" name="weight" rules="required">
                    <b-form-input id="weight" v-model="item.form.weight" type="number" placeholder="weight"
                      :state="errors.length > 0 || (item.form.weight && !/^\d+$/.test(item.form.weight)) ? false : null"
                      @change="handleFormChange()" />
                    <b-form-invalid-feedback tooltip>
                      {{ $t('calculate.notCharacters') }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xl="2" lg="2" md="4" sm="12">
                <b-form-group :label="$t('calculate.length') + ' CM'" label-for="length">
                  <validation-provider #default="{ errors }" name="length" rules="required">
                    <b-form-input id="length" v-model="item.form.length" type="number" placeholder="length"
                      :state="errors.length > 0 || (item.form.length && !/^\d+$/.test(item.form.length)) ? false : null"
                      @change="handleFormChange()" />
                    <b-form-invalid-feedback tooltip>
                      {{ $t('calculate.notCharacters') }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xl="2" lg="2" md="4" sm="12">
                <b-form-group :label="$t('calculate.width') + ' CM'" label-for="width">
                  <validation-provider #default="{ errors }" name="width" rules="required">
                    <b-form-input id="width" v-model="item.form.width" type="number" placeholder="width"
                      :state="errors.length > 0 || (item.form.width && !/^\d+$/.test(item.form.width)) ? false : null"
                      @change="handleFormChange()" />
                    <b-form-invalid-feedback tooltip>
                      {{ $t('calculate.notCharacters') }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xl="2" lg="2" md="4" sm="12">
                <b-form-group :label="$t('calculate.height') + ' CM'" label-for="height">
                  <validation-provider #default="{ errors }" name="height" rules="required">
                    <b-form-input id="height" v-model="item.form.height" type="number" placeholder="height"
                      :state="errors.length > 0 || (item.form.height && !/^\d+$/.test(item.form.height)) ? false : null"
                      @change="handleFormChange()" />
                    <b-form-invalid-feedback tooltip>
                      {{ $t('calculate.notCharacters') }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xl="2" lg="3" md="8" sm="12">
                <b-form-group :label="$t('calculate.property')" label-for="tags_ids">
                  <validation-provider #default="{ errors }" name="tags_ids" rules="required">
                    <b-form-select id="tags_ids" v-model="item.form.tags_ids" :options="tags"
                      :state="errors.length > 0 ? false : null" @change="handleFormChange()" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Remove Button -->
              <b-col xl="2" lg="3" md="4" sm="12" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2 remove"
                  @click="removeItem(index)">
                  <span>Delete</span>
                </b-button>
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-warning"
                  class="mt-0 mt-md-2 remove ml-1" @click="copyItem(index)">
                  <span>Copy</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-form>

    </div>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="repeateAgain">
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add Another Package</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [{
        id: 1,
        form: {
          tags_ids: null,
          qty: 1,
        },
      }],
      nextTodoId: 2,
    }
  },
  watch: {
    tags() {
      /* eslint-disable*/
      this.items.forEach(item => {
        item.form.tags_ids = null
      })
      this.handleFormChange()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        form: { tags_ids: null, qty: 1 },
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.$emit('handleGetPackages', [])
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.handleFormChange()
    },
    copyItem(index) {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        form: { ...this.items[index].form },
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        const item = [].concat(this.items)
        this.$emit('handleGetPackages', item)
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    handleGetPackages(isSucc = true) {
      const item = [].concat(this.items)
      this.$emit('handleGetPackages', item)
      if (!isSucc) {
        this.$emit('handleGetPackages', [])
      }
    },
    handleFormChange() {
      const success = []
      this.items.forEach(i => {
        if (i.form.qty && i.form.weight && i.form.length && i.form.width && i.form.height && i.form.tags_ids) {
          success.push(1)
        }
      })
      if (this.items.length === success.length) {
        this.handleGetPackages()
      } else {
        this.handleGetPackages(false)
      }
    },
    async validationForm() {
      // 用来校验
      const validArr = []
      return new Promise(async (resolve, reject) => {
        for(let i=0;i<this.$refs.simpleRules.length;i++) {
          validArr.push(await this.$refs.simpleRules[i].validate())
        }
        if(validArr.some(item => !item)) {
          resolve(false)
        }
        if(validArr.every(item => item)) {
          resolve(true)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.row {
  margin-top: initial;
}

.form-container {
  margin-top: 40px;
}

.remove {
  margin-top: 1.7rem !important;
  padding: 10px;
}

@media screen and (max-width: 575px) {
  .col-sm-8 {
    flex: 0 0 58%;
    max-width: 58%;
  }

  .col-sm-4 {
    flex: 0 0 42%;
    max-width: 42%;
  }
}
</style>
<style scoped>
.form-container ::v-deep .d-block {
  font-size: 14px;
}

.form-container ::v-deep .vs__dropdown-toggle {
  height: 2.714rem;
}

.form-container ::v-deep [dir] .vs__search,
[dir] .vs__search:focus {
  margin: 2px 0 0 0;
}

.form-container ::v-deep .valid-tooltip,
.invalid-tooltip {
  top: 80%;
  left: 15px;
}
.line-wrap{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.index{
  margin-bottom: 18px;
  margin-right: 10px;
}
</style>
