var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.items),function(item,index){return _c('validation-observer',{key:item.id,ref:"simpleRules",refInFor:true,staticClass:"form"},[_c('div',{staticClass:"line-wrap"},[_c('div',{staticClass:"index"},[_vm._v("#"+_vm._s(index + 1))]),_c('b-row',{ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"xl":"1","lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"QTY","label-for":"qty"}},[_c('validation-provider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"qty","type":"number","state":errors.length > 0 || (item.form.qty && !/^\d+$/.test(item.form.qty)) ? false : null,"placeholder":"qty"},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.qty),callback:function ($$v) {_vm.$set(item.form, "qty", $$v)},expression:"item.form.qty"}}),_c('b-form-invalid-feedback',{attrs:{"tooltip":""}},[_vm._v(" "+_vm._s(_vm.$t('calculate.notCharacters'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"1","lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calculate.weight') + ' KG',"label-for":"weight"}},[_c('validation-provider',{attrs:{"name":"weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"weight","type":"number","placeholder":"weight","state":errors.length > 0 || (item.form.weight && !/^\d+$/.test(item.form.weight)) ? false : null},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.weight),callback:function ($$v) {_vm.$set(item.form, "weight", $$v)},expression:"item.form.weight"}}),_c('b-form-invalid-feedback',{attrs:{"tooltip":""}},[_vm._v(" "+_vm._s(_vm.$t('calculate.notCharacters'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calculate.length') + ' CM',"label-for":"length"}},[_c('validation-provider',{attrs:{"name":"length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"length","type":"number","placeholder":"length","state":errors.length > 0 || (item.form.length && !/^\d+$/.test(item.form.length)) ? false : null},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.length),callback:function ($$v) {_vm.$set(item.form, "length", $$v)},expression:"item.form.length"}}),_c('b-form-invalid-feedback',{attrs:{"tooltip":""}},[_vm._v(" "+_vm._s(_vm.$t('calculate.notCharacters'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calculate.width') + ' CM',"label-for":"width"}},[_c('validation-provider',{attrs:{"name":"width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"width","type":"number","placeholder":"width","state":errors.length > 0 || (item.form.width && !/^\d+$/.test(item.form.width)) ? false : null},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.width),callback:function ($$v) {_vm.$set(item.form, "width", $$v)},expression:"item.form.width"}}),_c('b-form-invalid-feedback',{attrs:{"tooltip":""}},[_vm._v(" "+_vm._s(_vm.$t('calculate.notCharacters'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calculate.height') + ' CM',"label-for":"height"}},[_c('validation-provider',{attrs:{"name":"height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"height","type":"number","placeholder":"height","state":errors.length > 0 || (item.form.height && !/^\d+$/.test(item.form.height)) ? false : null},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.height),callback:function ($$v) {_vm.$set(item.form, "height", $$v)},expression:"item.form.height"}}),_c('b-form-invalid-feedback',{attrs:{"tooltip":""}},[_vm._v(" "+_vm._s(_vm.$t('calculate.notCharacters'))+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"2","lg":"3","md":"8","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calculate.property'),"label-for":"tags_ids"}},[_c('validation-provider',{attrs:{"name":"tags_ids","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"tags_ids","options":_vm.tags,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.handleFormChange()}},model:{value:(item.form.tags_ids),callback:function ($$v) {_vm.$set(item.form, "tags_ids", $$v)},expression:"item.form.tags_ids"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"xl":"2","lg":"3","md":"4","sm":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2 remove",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('span',[_vm._v("Delete")])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2 remove ml-1",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.copyItem(index)}}},[_c('span',[_vm._v("Copy")])])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)],1)])}),1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Another Package")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }